<template>
  <div>
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      class="home"
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="onLoad"
      @page-size-change="onLoad">
      <template #dateAndTime="{row}">
        <span v-if="['overseasCode','domesticCode'].includes(row.reportCode)">
          {{ row.dateAndTime.substring(0, 10) }}
        </span>
        <span v-else>
          {{ row.dateAndTime.substring(0, 7) }}
        </span>
      </template>
    </grid-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {selectPage} from "@/api/report/reportBackspaceLog";

export default {
  components: {
    HeadLayout,
    GridLayout,
  },
  data() {
    return {
      fullscreen: false,
      safeType: 'edit',
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      searchColumns: [
        {
          label: "报表名称",
          prop: "reportCode",
          type: "select",
          dicUrl: "/api/sinoma-report/acquisition/attributeFixation/authorityReport?type=fill",
          dicData: [],
          props: {
            label: "templateName",
            value: "templateCode",
          },
          span: 4,
          placeholder: "请选择报表名称",
        },
        {
          label: "填报组织",
          prop: "backspaceDeptName",
          type: "select",
          dicUrl: "/api/sinoma-report/rpBackspaceLog/fillSummaryList",
          dicData: [],
          props: {
            label: "backspaceDeptName",
            value: "backspaceDeptName",
          },
          span: 4,
          placeholder: "请选择填报组织",
        },
        {
          label: "统计时间",
          prop: "dateAndTime",
          type: "date",
          format: "yyyy-MM-dd",
          dataFormat: "yyyy-MM-dd HH:mm:ss",
          span: 4,
          placeholder: "请选择统计日期",
          hide: true,
        },
      ],
      tableData: [],
      activeName: "first",
      tableLoading: false,
      tableOption: {
        menu: false,
        index: true,
        indexLabel: "序号",
        selection: false,
        column: [
          {
            label: "报表名称",
            prop: "reportName",
            align: "left",
            width: 210,
            overHidden: true,
          },
          {
            label: "被退回组织",
            prop: "backspaceDeptName",
            width: 240,
            align: "left",
            overHidden: true,
          },
          {
            label: "统计时间",
            prop: "dateAndTime",
            width: 120,
            align: "center",
            slot: true
          },
          {
            label: "被退回人",
            prop: "backspaceUserName",
            align: "center",
            width: 80,
            overHidden: true,
          },
          {
            label: "退回原因",
            prop: "backspaceReason",
            align: "center",
            overHidden: true,
          },
          {
            label: "退回次数",
            prop: "backspaceNumber",
            align: "center",
            width: 80,
            overHidden: true,
          },
        ]
      }
    }
  },
  mounted() {
    this.onLoad(this.page, {});
    window.addEventListener("storage", (e) => {
      if (e.key === "sinoma-report") {
        this.onLoad(this.page); //刷新列表
        localStorage.setItem("sinoma-report", JSON.stringify(false)); //变成false , 可以来回监听
        //要做的操作
      }
    });
  },
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    globalBoxClose() {
      this.onLoad(this.page, {});
    },
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      params.fillSummaryType = 'fill'
      params.type = this.activeName
      selectPage(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.tableData = data.records
        this.page.total = data.total;
        this.$refs.gridLayOut.page.total = data.total
        this.tableLoading = false;
        this.selectionList = [];
      }).catch(() => {
        this.tableLoading = false;
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    rowView(row) {
      this.$emit('runApproval', row)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .home .avue-crud .el-table {
  height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}

.homeTopTabs {
  width: calc(100% - 24px);
  padding: 0 12px;
  background-color: #FFF;
}

.homeTopTabs ::v-deep .el-tabs__header {
  margin: 0;
}
</style>
